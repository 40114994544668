$fa-font-path: '../Fonts';
@import "Fonts";
@import "Components";
@import "cookieConsent";

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  @font-face {
    font-family: "Foco";
    font-weight: 500;
    src: 
      url(../Fonts/Foco_W_Rg.woff) format("woff");
  }
  @font-face {
    font-family: "Foco Light";
    font-weight: 300;
    src: 
      url(../Fonts/Foco_W_Lt.woff) format("woff");
  }
  @font-face {
    font-family: "Foco Bold";
    font-weight: 700;
    src: url(../Fonts/Foco_W_Bd.woff) format("woff");
  }
  html {
    font-family: 'Foco';
  }
  b, strong {
    font-family: 'Foco Bold';
  }
  h1 {
    font-family: 'Foco';
    
  }
  h2 {
    font-family: 'Foco Light';
    letter-spacing: 4px;
    font-size: 2.5rem;
    line-height: 2.375rem;
    
    @screen lg {
      font-size: 3.5rem;
      line-height: 4.25rem;
    }
  }
  h4 {
    font-family: "Foco";
    font-size: 1.6rem;
    font-weight: 300;
    border-bottom: 1px #707070 solid;
    padding-bottom: 16px;
    margin-bottom: 20px;
    letter-spacing: 2px;
    line-height: 36px;
  }
  a {
      @apply text-green
  }
  
}

svg {
  color: #fff;
}

p {
  padding-bottom: 1rem;
  &:last-child {
    padding-bottom: 0;
  }
}

.text-container {
  ul {
    margin-left: 1.25rem;
    list-style-type: circle;
  }
  ol {
    margin-left: 1.25rem;
    list-style-type: decimal;
  }
}

@layer components {
  .container {
    padding: 1rem;
  }
  ken-burns-carousel {
    img {
      filter: var(--img-filter);
      object-fit: contain;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .ken-burns {
    position: relative;
    height: 0;
    padding-bottom: 45%;
  }
  ken-burns-carousel, pre {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 10px;
  }
}

footer {
  .ck-editor__editable_inline {
    display: inline-block;
  }
}