.siteHeader {
  @apply fixed z-[1000] w-full min-w-[20em] h-[60px] transition-all duration-[0.5s] ease-[ease] text-white left-0 top-0 md:h-20;
  background: #000032;
}
.siteHeader--hidden {
  @apply -translate-y-full;
}
.siteHeader--shaded {
  @apply h-[55px];
  background: #000032;
}
